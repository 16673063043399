<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class=" primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }"
          >Dashboard
        </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip active-class="false" :to="{ name: 'Products' }"
          >Products
        </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'Imarika' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-2 md:tw-mt-10">
      <card-title icon="/images/icons/top-icon.png"
        >Imarika Plan Calculator</card-title
      >
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
            :is="current"
            :productData="productData"
            :benefits="benefits"
            :terms="terms"
            :quoteSummaryData="quoteSummaryData"
            @to-quote-summary="toQuoteSummary"
          ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/ClientQuotation';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';

export default {
  mounted() {
    this.checkSavedQuote('imarika');
  },
  name: 'ImarikaPlan',
  metaInfo: {
    title: 'RFQ - Imarika Plan',
  },
  components: {
    QuoteSummary,
    BackButton,
    CardTitle,
    Default,
    ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2013104, optionCode: 2012146 },
      benefits: [
        { coverName: 'Accidental Death', coverProdCode: 2013513 },
        { coverName: 'Total & Permanent Disability', coverProdCode: 2013512 },
        { coverName: 'Waiver of Premium', coverProdCode: 2013511 },
        { coverName: 'Medical Reimbursement', coverProdCode: 2013515 },
        { coverName: 'Critical Illness', coverProdCode: 2013514 },
        { coverName: 'Retrenchment', coverProdCode: 2016612 },
      ],
      terms: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      quoteSummaryData: {},
    };
  },
};
</script>
